<div class="close-container">
    <i class="close material-icons-outlined" (click)="dialogClose()" *ngIf="processFinished">close</i>
</div>
<div class="row pl-4 pr-4 justify-content-center align-items-center">
    <div class="col upload-progress d-flex justify-content-center">
        <circle-progress
            [percent]="percentage"
            [startFromZero]="false"
            [showUnits]="true"
            [showSubtitle]="true"
            [radius]="80"
            [outerStrokeWidth]="8"
            [innerStrokeWidth]="4"
            [outerStrokeColor]="'#78C000'"
            titleFontSize="24"
            subtitleFontSize="14"
            titleFontWeight="600"
            [animation]="true"
            [animationDuration]="300"
            [subtitle]="subtitleFormat()"
        ></circle-progress>
    </div>
    <div class="col">
        <table class="table table-borderless table-vmiddle">
            <thead class="header-table">
            <tr>
                <th scope="col" translate>common.status</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td class="text-start">
                    <div class="d-flex align-items-center justify-content-between">
                        <span translate>{{row.label}}</span>
                        <ng-container
                            [ngTemplateOutlet]="
                                statusIsDone(row.status) || statusIsTodoOrInProgress(row.status)
                                    ? stepInProgress
                                    : stepError
                            "
                            [ngTemplateOutletContext]="{ row: row }">
                        </ng-container>

                    </div>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</div>

<ng-template #stepInProgress let-row="row">
    <i class="material-icons-outlined text-success" *ngIf="statusIsDone(row.status)">
        check
    </i>
    <i class="material-icons-outlined text-warning" *ngIf="statusInProgress(row.status)"
        tooltipPos="up-right"
        tooltipSize="large">
        pending
    </i>
</ng-template>

<ng-template #stepError let-row="row">
    <span class="material-icons white text-danger"
        tooltipPos="up-right"
        tooltipSize="large"
        [matTooltip]="row.tooltip"
        translate>
        error
    </span>
</ng-template>
